

import { Component, Vue, Ref } from 'vue-property-decorator'
import { DeviceDetail, DeviceTypeDetail } from '@/types/devices'
import { ElForm } from 'element-ui/types/form'
import { Page } from '@/types/common'
import { ElTable } from 'element-ui/types/table'
import { deviceStatusMap, allocateStatusMap } from '@/utils/dict'
import { KeepAlive } from '@/utils/decorators'

interface Table extends ElTable {
  selection: DeviceDetail[];
}

@Component({
  name: 'DeviceList'
})
@KeepAlive
export default class DeviceList extends Vue {
  @Ref('table') readonly elTable!: Table
  @Ref('form') readonly elForm!: ElForm

  deviceTypeList: DeviceTypeDetail[] = []
  deviceStatusMap = deviceStatusMap
  allocateStatusMap = allocateStatusMap

  searchInfo: object = {
    deviceTypeId: '',
    deviceCode: '',
    deviceNumber: '',
    customerId: '',
    supplierId: '',
    deviceStatus: '',
    isAllocated: ''
  }

  total = 0
  page = 1
  size = 10
  loading = false
  tableData: DeviceDetail[] = []
  showAllocateDialog = false
  allocateLoading = false
  allocateInfo: {deviceIds: string[]; projectId: string} = {
    deviceIds: [],
    projectId: ''
  }

  allocateRules = {
    projectId: [
      { required: true, message: '请选择项目', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  get selection () {
    return this.elTable.selection
  }

  created () {
    this.$store.dispatch('getProjectList')
    this.getDeviceTypeList()
    this.getList()
  }

  onSearch () {
    this.page = 1
    this.getList()
  }

  getDeviceTypeList () {
    this.$axios.get<Page<DeviceTypeDetail>>(this.$apis.devices.getDeviceTypeList).then(res => {
      this.deviceTypeList = res.list
    })
  }

  // 获取列表数据
  getList () {
    this.loading = true
    this.$axios.get<Page<DeviceDetail>>(this.$apis.devices.selectDeviceByPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total
      this.tableData = res.list
    }).finally(() => {
      this.elTable.clearSelection()
      this.loading = false
    })
  }

  batchAllocate () {
    if (this.selection.length) {
      this.showAllocateDialog = true
      this.allocateInfo.deviceIds = this.selection.map(item => item.deviceId)
    } else {
      this.$message.error('请选择要分配的设备')
    }
  }

  allocate (row: DeviceDetail) {
    this.showAllocateDialog = true
    this.allocateInfo.deviceIds = [row.deviceId]
  }

  // 分配数据校验
  allocateDevice () {
    this.elForm.validate(valid => {
      if (valid) {
        this.doAllocateDevice()
      }
    })
  }

  // 分配执行
  doAllocateDevice () {
    this.allocateLoading = true
    this.$axios.post(this.$apis.devices.batchAllocateDevice, this.allocateInfo).then(() => {
      this.$message.success('分配成功')
      this.elTable.clearSelection()
      this.getList()
    }).finally(() => {
      this.allocateLoading = false
      this.showAllocateDialog = false
    })
  }

  recycle (row: DeviceDetail) {
    this.$confirm('确定回收该设备嘛？', {
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.devices.recycleDevice, {
        deviceIds: [row.deviceId]
      }).then(() => {
        this.$message.success('操作成功')
        this.getList()
      })
    })
  }

  // 判断设备是否能分配
  selectable (row: {isAllocated: string}) {
    if (row.isAllocated === '1') {
      return true// 非禁用状态
    } else {
      return false// 禁用状态
    }
  }
}
